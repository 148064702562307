body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(235, 235, 235);
  height: calc(100vh - 60px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-content {
  margin-top: 60px !important;
  height: calc(100vh - 60px);
  width: 100%;
}

.nav-top {
  height: 60px !important;
}

.custom-container {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: auto;
  margin-left: auto;
  margin-right: 0;
  padding: 30px 10px;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .custom-container {
    width: 82%;
    padding: 20px 40px;
  }
}

.nav-link {
  transition: all 0.2s ease-in-out;
  color: #202020;
}

.nav-link:hover {
  color: #202020;
  background: rgb(241, 241, 241);
  margin-left: 5px;
  border-left: 2px solid rgb(0, 161, 224);
}

.loading-cover {
  background: rgba(0, 0, 0, 0.123);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.text-success {
  color: #07b910 !important;
}

.btn-success {
  background-color: #62d11b !important;
  border-color: #62d11b !important;
}

.btn-success:hover {
  color: #fff !important;
  background-color: #4ba017 !important;
  border-color: #4ba017 !important;
}

.bg-light {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #585858 !important;
}

.pass_recover:hover {
  border-color: #0dcaf0;
}

.sit-doc-item-row {
  transition: 0.2s all linear;
}

.sit-doc-item-row:hover {
  background: rgba(153, 153, 153, 0.288);
}
