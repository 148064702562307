.nav-side {
  margin-top: 60px !important;
  position: fixed;
  height: calc(100vh - 60px);
  left: 0;
  top: 0;
  padding: 20px 0;
  overflow-y: scroll;
}

.nav-side::-webkit-scrollbar {
  width: 4px;
}

.nav-side::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
}

.nav-side::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  outline: none;
  border-radius: 10px;
}

@media only screen and (min-width: 1024px) {
  .nav-side {
    width: 18%;
  }
}
