.rec-row-title {
  width: 60%;
}

.rec-row-date {
  display: none;
}

.rec-row-options {
  width: 40%;
}

.rec-row-btn-read {
  display: none;
}

@media only screen and (min-width: 768px) {
  .rec-row-date {
    display: unset;
    width: 20%;
  }
  .rec-row-options {
    width: 20%;
  }
  .rec-row-btn-read {
    display: unset;
  }
}
