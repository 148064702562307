.login-screen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: #f8f9fa;
}

.login-screen .login-card {
  width: 100%;
  margin: 0 20px;
}

@media only screen and (min-width: 768px) {
  .login-screen .login-card {
    width: 50%;
    margin: 0 0;
  }
}

@media only screen and (min-width: 1440px) {
  .login-screen .login-card {
    width: 30%;
  }
}
